<template>
  <div class="container">
    <SubHeader :pathList="pathList" :title="title" />

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('environment.register-title') }}</h3>
        <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
      </div>
      <!--/title-sort-box-->
      <div class="table-horizon st01">
        <ValidationObserver ref="form">
          <form id="InsertForm" enctype="multipart/form-data">
            <table class="form-md">
              <colgroup>
                <col style="width:10%" />
                <col style="width:37%" />
                <col style="width:16%" />
                <col style="width:37%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <label class="ic-star">{{
                      $t('environment.label-serviceId')
                    }}</label>
                  </th>
                  <td>
                    <span class="isf-with">
                      <text-input
                        :rules="{
                          required: true,
                          regex: /[0-9A-Za-z\-_]{4,20}/,
                        }"
                        label="Service ID"
                        name="srvcId"
                        maxlength="20"
                        v-model.trim="srvcId"
                        :placeholder="srvcIdPlaceholder"
                      />

                      <button
                        type="button"
                        class="bt-md bt-default"
                        @click="checkServiceIdValidate"
                      >
                        {{ $t('environment.btn-check-serviceId') }}
                      </button>
                    </span>
                  </td>
                  <th>&nbsp;</th>
                  <td>&nbsp;</td>
                </tr>
                <tr>
                  <th>
                    <label class="ic-star">{{
                      $t('environment.label-startDate')
                    }}</label>
                  </th>
                  <td>
                    <date-picker
                      v-model="analsBegin"
                      mode="dateTime"
                      :masks="{ inputDateTime: 'YYYY/MM/DD HH:mm:00' }"
                    >
                      <template #default="{ inputValue, togglePopover}">
                        <span class="daterange-wrap w100">
                          <text-input
                            rules="required"
                            label="start"
                            name="analsBegin"
                            maxlength="20"
                            :value="inputValue"
                            @click="togglePopover()"
                            placeholder="yyyy/mm/dd hh:MM:ss"
                          />

                          <label
                            for="analsBegin"
                            @click="togglePopover()"
                            class="ic-calendar"
                          ></label>
                        </span>
                      </template>
                    </date-picker>
                  </td>
                  <th>
                    <label class="ic-star">{{
                      $t('environment.label-endDate')
                    }}</label>
                  </th>
                  <td>
                    <date-picker
                      v-model="analsEnd"
                      mode="dateTime"
                      :attributes="attributes"
                      :masks="{ inputDateTime: 'YYYY/MM/DD HH:mm:00' }"
                    >
                      <template #default="{ inputValue, togglePopover}">
                        <span class="daterange-wrap w100">
                          <text-input
                            rules="required"
                            label="end"
                            name="analsEnd"
                            maxlength="20"
                            @click="togglePopover()"
                            :value="inputValue"
                            placeholder="yyyy/mm/dd hh:MM:ss"
                          />

                          <label
                            for="analsEnd"
                            @click="togglePopover()"
                            class="ic-calendar"
                          ></label>
                        </span>
                      </template>
                    </date-picker>
                  </td>
                </tr>
                <tr>
                  <th>
                    <label class="ic-star">{{
                      $t('environment.label-tool')
                    }}</label>
                  </th>
                  <td>
                    <text-input
                      rules="required"
                      label="Analysis Tools"
                      name="analsUnt"
                      maxlength="200"
                      v-model.trim="analsUnt"
                    />
                  </td>

                  <th>
                    <label class="ic-star">{{
                      $t('environment.label-data')
                    }}</label>
                  </th>
                  <td>
                    <text-input
                      rules="required"
                      label="Analysis data"
                      name="analsData"
                      maxlength="200"
                      v-model.trim="analsData"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </ValidationObserver>
      </div>

      <div class="btns-bottom">
        <button type="button" class="bt-md bt-default" @click="restFormData">
          <span>{{ $t('common.btn-cancel') }}</span>
        </button>
        <button type="button" class="bt-md bt-red" @click="checkValidate">
          <span>{{ $t('common.btn-submit') }}</span>
        </button>
      </div>
    </div>
    <!--/contents-->
  </div>
  <!--/container-->
</template>
<script>
import TextInput from '@/components/common/TextInput.vue';
import CommonMixins from '@/mixins/CommonMixins';
import {
  serviceIdUsable,
  insertAnalysisEnvironment,
} from '@/api/requestApi.js';
export default {
  components: { TextInput },
  data() {
    return {
      pathList: [],
      title: {},
      srvcIdPlaceholder: '',
      srvcId: '',
      analsBegin: '',
      analsEnd: '',
      analsUnt: '',
      analsData: '',
      srvidUsable: 'N',
      masks: {
        input: 'YYYY/MM/DD hh:mm:00',
      },
      attributes: [
        {
          order: 1000,
        },
      ],
    };
  },
  created() {
    this.initLocale();
  },
  methods: {
    // 메뉴, placeholder 등 문구 set
    initLocale() {
      this.pathList.push(this.$i18n.t('menu.home'));
      this.pathList.push(this.$i18n.t('menu.solutions'));
      this.pathList.push(this.$i18n.t('subMenu.environment'));

      this.title.mainTitle = this.$i18n.t('menu.solutions');
      this.title.smallTitle = this.$i18n.t('menu.solutions-eng');

      this.srvcIdPlaceholder = this.$i18n.t(
        'common.placeholder.id-format-max',
        { max: 20 },
      );
    },

    // 서비스 아이디 유효성 검사
    checkServiceIdValidate() {
      if (this.srvcId === '') {
        this.$dialogs.alert(this.$i18n.t('environment.alert-empty-srvcId'));
        return;
      } else if (this.srvcId.length < 4) {
        this.$dialogs.alert(
          this.$i18n.t('common.alert-textLenth-invalid', {
            min: 4,
            max: 20,
            field: this.$i18n.t('environment.label-serviceId'),
          }),
        );
        return;
      } else {
        this.serviceIdUsable();
      }
    },
    // 서비스 아이디 사용가능 여부 검사
    async serviceIdUsable() {
      const srvcId = this.srvcId;
      const {
        data: { valid },
      } = await serviceIdUsable({ srvcId });

      if (valid) {
        this.$dialogs.alert(this.$i18n.t('environment.alert-id-valid'));
        this.srvidUsable = 'Y';
      } else {
        this.$dialogs.alert(this.$i18n.t('environment.alert-id-invalid'));
        this.srvidUsable = 'N';
      }
    },
    // 페이지 전체 유효성 검사
    checkValidate() {
      this.$refs.form.validate().then(res => {
        if (!res) {
          this.$dialogs.alert(this.$i18n.t('common.invalid-required'));
          return;
        }
      });

      if (this.srvidUsable === 'N') {
        this.$dialogs.alert(this.$i18n.t('environment.alert-checkAvail'));
        return;
      }
      this.insertAnalysisEnvironment();
    },
    // 분석환경 이용신청 등록
    async insertAnalysisEnvironment() {
      let formData = {
        srvcId: this.srvcId,
        analsBegin: CommonMixins.methods.common_getDateString(this.analsBegin),
        analsEnd: CommonMixins.methods.common_getDateString(this.analsEnd),
        analsUnt: this.analsUnt,
        analsData: this.analsData,
      };
      const {
        data: { valid },
      } = await insertAnalysisEnvironment(formData);
      if (valid) {
        this.$dialogs.alert(this.$i18n.t('common.alert-submit-success'));
        this.restFormData();
      } else {
        this.$dialogs.alert(this.$i18n.t('common.alert-submit-fail'));
      }
    },
    // 폼 초기화
    restFormData() {
      this.$refs.form.reset();
      Object.assign(this.$data, this.$options.data());
      this.initLocale();
    },
  },
};
</script>
<style>
.vc-popover-content-wrapper {
  z-index: 101 !important;
}
</style>
