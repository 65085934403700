var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('SubHeader',{attrs:{"pathList":_vm.pathList,"title":_vm.title}}),_c('div',{staticClass:"contents",attrs:{"id":"contents"}},[_c('div',{staticClass:"title-sort-box"},[_c('h3',[_vm._v(_vm._s(_vm.$t('environment.register-title')))]),_c('p',{staticClass:"red-txt",domProps:{"innerHTML":_vm._s(_vm.$t('common.required-input-guide'))}})]),_c('div',{staticClass:"table-horizon st01"},[_c('ValidationObserver',{ref:"form"},[_c('form',{attrs:{"id":"InsertForm","enctype":"multipart/form-data"}},[_c('table',{staticClass:"form-md"},[_c('colgroup',[_c('col',{staticStyle:{"width":"10%"}}),_c('col',{staticStyle:{"width":"37%"}}),_c('col',{staticStyle:{"width":"16%"}}),_c('col',{staticStyle:{"width":"37%"}})]),_c('tbody',[_c('tr',[_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('environment.label-serviceId')))])]),_c('td',[_c('span',{staticClass:"isf-with"},[_c('text-input',{attrs:{"rules":{
                        required: true,
                        regex: /[0-9A-Za-z\-_]{4,20}/,
                      },"label":"Service ID","name":"srvcId","maxlength":"20","placeholder":_vm.srvcIdPlaceholder},model:{value:(_vm.srvcId),callback:function ($$v) {_vm.srvcId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"srvcId"}}),_c('button',{staticClass:"bt-md bt-default",attrs:{"type":"button"},on:{"click":_vm.checkServiceIdValidate}},[_vm._v(" "+_vm._s(_vm.$t('environment.btn-check-serviceId'))+" ")])],1)]),_c('th'),_c('td')]),_c('tr',[_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('environment.label-startDate')))])]),_c('td',[_c('date-picker',{attrs:{"mode":"dateTime","masks":{ inputDateTime: 'YYYY/MM/DD HH:mm:00' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var inputValue = ref.inputValue;
                      var togglePopover = ref.togglePopover;
return [_c('span',{staticClass:"daterange-wrap w100"},[_c('text-input',{attrs:{"rules":"required","label":"start","name":"analsBegin","maxlength":"20","value":inputValue,"placeholder":"yyyy/mm/dd hh:MM:ss"},on:{"click":function($event){return togglePopover()}}}),_c('label',{staticClass:"ic-calendar",attrs:{"for":"analsBegin"},on:{"click":function($event){return togglePopover()}}})],1)]}}]),model:{value:(_vm.analsBegin),callback:function ($$v) {_vm.analsBegin=$$v},expression:"analsBegin"}})],1),_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('environment.label-endDate')))])]),_c('td',[_c('date-picker',{attrs:{"mode":"dateTime","attributes":_vm.attributes,"masks":{ inputDateTime: 'YYYY/MM/DD HH:mm:00' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var inputValue = ref.inputValue;
                      var togglePopover = ref.togglePopover;
return [_c('span',{staticClass:"daterange-wrap w100"},[_c('text-input',{attrs:{"rules":"required","label":"end","name":"analsEnd","maxlength":"20","value":inputValue,"placeholder":"yyyy/mm/dd hh:MM:ss"},on:{"click":function($event){return togglePopover()}}}),_c('label',{staticClass:"ic-calendar",attrs:{"for":"analsEnd"},on:{"click":function($event){return togglePopover()}}})],1)]}}]),model:{value:(_vm.analsEnd),callback:function ($$v) {_vm.analsEnd=$$v},expression:"analsEnd"}})],1)]),_c('tr',[_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('environment.label-tool')))])]),_c('td',[_c('text-input',{attrs:{"rules":"required","label":"Analysis Tools","name":"analsUnt","maxlength":"200"},model:{value:(_vm.analsUnt),callback:function ($$v) {_vm.analsUnt=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"analsUnt"}})],1),_c('th',[_c('label',{staticClass:"ic-star"},[_vm._v(_vm._s(_vm.$t('environment.label-data')))])]),_c('td',[_c('text-input',{attrs:{"rules":"required","label":"Analysis data","name":"analsData","maxlength":"200"},model:{value:(_vm.analsData),callback:function ($$v) {_vm.analsData=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"analsData"}})],1)])])])])])],1),_c('div',{staticClass:"btns-bottom"},[_c('button',{staticClass:"bt-md bt-default",attrs:{"type":"button"},on:{"click":_vm.restFormData}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.btn-cancel')))])]),_c('button',{staticClass:"bt-md bt-red",attrs:{"type":"button"},on:{"click":_vm.checkValidate}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.btn-submit')))])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }